@import '../base/settings';
.mainDiv {
    transition: 0.5s all;
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-areas: 'leftSide rightSide';
    margin-left: $marginLeft;
    margin-top: $marginTop;
    .leftSide {
        grid-area: leftSide;
    }
    .rightSide {
        grid-area: rightSide;
        display: flex;
        flex-direction: column-reverse;
        .background {
            position: fixed;
            top: 5vh;
            width: 40vw;
        }
        .questions {
            height: 50%;
            width: 100%;
        }
    }
}

.documentSliderBackground {
    left: 0;
    top: 0;
    background-color: black;
    opacity: 0.6;
    height: 1200px;
    width: 100%;
    position: absolute;
    -webkit-animation: fadein 0.7s;
    -moz-animation: fadein 0.7s;
    -ms-animation: fadein 0.7s;
    -o-animation: fadein 0.7s;
    animation: fadein 0.7s;
}

.documentSlider {
    margin-top: 5%;
    width: 100%;
    height: 1px;
    position: absolute;
    display: flex;
    justify-content: center;
    top: 0;
}

.qrstyle {
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 280px;
    text-align: center;
    padding-left: 5px;
    padding-right: 100px;
    margin-bottom: 200px;
    z-index: 50;
    margin-left: 10px;
}

.phone img {
    margin-bottom: 0px;
    padding-top: 0px;
    padding-left: 250px;
    z-index: 499;
}

.hint-list{
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
    align-content: flex-start;
    padding-left: 0;
}
.hint-list li{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.hint-list img{
    width: 25px;
    max-height: 35px;
    margin-right: 10px;
}

.box-qr-option{
    width: 100%;
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}
.h2-style{

}
.subtitle-style{

}
.description-style{
    
}

@media (max-width: 600px) {
    .hint-list{
        margin-top: 20px;
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.6;
    }
}

// Firefox < 16
@-moz-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.6;
    }
}

// Safari, Chrome and Opera > 12.1
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.6;
    }
}

//Internet Explorer
@-ms-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.6;
    }
}
