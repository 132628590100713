/* Size according to screen */

@media (min-width: 1200px)  and (max-width: 9999px) {
    .desktop-950px{
        width: 950px;
    }
    .desktop-750px{
        width: 750px;
    }
    .desktop-350px{
        width: 350px;
    }
    
    
}

@media (min-width: 1000px) and (max-width: 1200px) {
    .desktop-950px{
        width: 850px;
    }
    
    
}

@media (min-width: 800px) and (max-width: 1000px) {
    .desktop-950px{
        width: 750px;
    }
       
}

@media (min-width: 600px) and (max-width: 800px) {
    .desktop-950px{
        width: 550px;
    }
       
}


@media (max-width: 900px) {
    .mobile-100per{
        width: 100%;
    }
}