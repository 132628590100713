.toc {
    top: 0;
    width: 80%;
    z-index: 999;
    background-color: black;
}

.alternativeToc {
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: black;
}

.tocContainer {
    position: fixed;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: black;
}

.captureButton {
    position: absolute;
    margin-left: 570px;
    margin-top: 30px;
    width: 10%;
    height: 50px;
    z-index: 9999;
}

@media (orientation: portrait){
    #toc-autocapture-camera{
        margin-top: 50px;
    }
    .button-toc-alternative{
        transform: rotate(90deg) !important;
        color: white;
        display: block !important;
        position: fixed !important;
        right: -45% !important;
        top: 45% !important;
    }
}