.stepper {
    width: 100%;

    .totalStep {
        width: 100%;
        height: 4px;
        background-color: #d4d4d4;
        border-radius: 2px;
    }
    .stepStage25 {
        margin-top: -4px;
        width: 25%;
        height: 4px;
        background-color: #092f57;
    }
    .stepStage50 {
        margin-top: -4px;
        width: 50%;
        height: 4px;
        background-color: #092f57;
    }
    .stepStage75 {
        margin-top: -4px;
        width: 75%;
        height: 4px;
        background-color: #092f57;
    }
    .stepStage100 {
        margin-top: -4px;
        width: 100%;
        height: 4px;
        background-color: #092f57;
    }
}

.rounderedStepper {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background-color: #092f57;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .p {
        color: white;
        font-size: 20px;
        font-weight: 400;
    }
}
.verticalCenterWeb{
    align-items: center;
}
.horizontalStartmobile{
    align-items: flex-start;
}
.error-message {
    background-color: #fbd6d6;
    border-radius: 4px;
    padding: 10px 20px;
}

@media (max-width: 992px){
    .rounderedStepper {
        width: 22px;
        height: 22px;
        .p {
            font-size: 14px;
        }
    }  
    .verticalCenterWeb{
        align-items: unset;
    }  
    .horizontalStartmobile{
        align-items: flex-start;
    }
}
