.card-delivery-info{
  margin: 0 0px 10px;
  width: 100%;
  border: 1px solid #CACACA;
  box-sizing: border-box;
  border-radius: 20px !important;
  box-shadow: none !important;
  background-color: #FFFFFF !important;
}
.card-delivery-info.active {
  border: 3px solid #203AE9;
  box-sizing: border-box;
  border-radius: 20px !important;
  background-color: #FFFFFF !important;
}
.card-delivery-info.active p {
  color: #4A4A4A !important;
}
.card-delivery-info p.title {
  color: #203AE9;
}
.card-delivery-info.active p.title {
  color: #203AE9 !important;
}
.card-delivery-info p.description {
  color: #515151;
}

.card-delivery-info .check-icon {
  display: none;
}
.card-delivery-info.active .check-icon {
  display: block;
  position: absolute;
  right: 20px;
  top: 20px;
  width: 20px;
}