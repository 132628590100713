@import '../base/settings';

@media (min-width: 600px) {
    .mainDiv {
        .importantdiv {
            display: grid;
            background-color: $backgroundInfo;
            border-radius: 8px;
            width: 100%;
            padding-left: 2%;
            padding-top: 2%;
            padding-bottom: 2%;
            grid-template-columns: 5% 95%;
            .sideLeft {
                display: inline-flex;
                align-self: center;
            }
            .sideRight {
                padding-left: 2%;
                display: flex;
                flex-direction: column;
            }
        }
    }
}

.selecteddiv {
    background-color: $backgroundInfo;
    border-radius: 8px;
    padding: 0.8em 1em;
    font-size: 1em;
    line-height: 1.5em;
    text-align: left;
}

@media (max-width: 600px) {
    .mainDiv {
        padding: 0;
        margin: 0;
    }
}

.normalization-modal-form-group {
    border: 1px solid gray;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-bottom: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 15px;
    border-radius: 10px;
}

.normalization-modal-form-group-selected {
    border: 1px solid #092f57;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-bottom: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 15px;
    border-radius: 10px;
}

.normalization-modal-form-group > span,
.normalization-modal-form-group-selected > span {
    font-size: 12px;
    color: #515151;
}
