@import '../base/settings';

.box {
    display: grid;
    background: rgba(168, 226, 253, 0.5);
    border-radius: 8px;
    color: #515151;
    font-family: 'Titillium Web', sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #515151;
    a {
        color: #082f57;
    }
}
@media (min-width: 600px) {
    .box {
        grid-template-columns: 10% 90%;
        padding: 20px;
        align-items: center;
        font-size: 16px;
        line-height: 1.75;
        img: {
        }
    }
}

@media (max-width: 600px) {
    .box {
        grid-template-columns: 25% 75%;
        justify-items: left;
        align-items: center;
        padding: 10px;
        font-size: 14px;
        line-height: 1.64;
        img {
            width: 75%;
        }
    }
}
