
.package-info-box{
    background-color: white;
    border-radius: 20px;
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.25);
    font-size: 1rem;
    margin-top: 30px;
}
.disclaimer-text-button{
    padding: 0 40px 20px 40px;
    font-size: 0.8rem;
    line-height: 1rem;
    color: #6A6A6A;
}
.benefits-box{
    font-size: 0.9rem;
    ul{
        padding: 10px 0 0 20px;
    }
    .benefits-disclaimer{
        font-size: 0.7rem;
    }
}
.credit-card-selected-img {
    width: 50%;
    height: auto;
    border-radius: 7px;
    margin-top: -60px;
    margin-bottom: 15px;
}
.tc-text-disclosure{
    font-size: 0.8rem;
    padding: 10px 20px 20px;
}
.legal-text{
    font-size: 0.9rem;
}
.credit-card-detail-list{
    font-size: 0.9rem;
}

@media (min-width: 600px) {
    // TODO: This style probably belongs at the theme level
    .stepButton {
        background-color: #092f57;
        width: 300px;
        margin-bottom: 100px;
    }
}
