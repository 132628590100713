@import '../base/settings';

.thumbChecked{
    font-size: 16px;
    font-weight: 700;
    padding: 0 10px 0;
    color: $secondary;
}
.thumbUnchecked{
    font-size: 16px;
    font-weight: 500;
    color: #9b9b9b;
    padding: 0 10px 0;
}
.switch-title{
    line-height: 1.2rem;
}