@media (min-width: 900px) {
    

        .formGrid {
            //border: 2px solid rgb(0, 140, 255);
            display: grid;
            grid-column-gap: 60px;
            grid-row-gap: 20px;
            grid-template-columns: 1fr 1fr;
            width: 80%;
            height: auto;
        }
        .inputGrid {
            display: flex;
            justify-content: center;
            align-items: stretch;
            flex-direction: column;
            height: 60px;
            width: 100%;
        }
    }


@media (max-width: 900px) {
    
        .formGrid {
           //  border: 2px solid rgb(0, 140, 255);
            display: grid;
            grid-column-gap: 60px;
            grid-row-gap: 10px;
            grid-template-columns: 1fr;
            width: 100%;
            height: auto;
        }
        .inputGrid {
            display: flex;
            justify-content: center;
            align-items: stretch;
            flex-direction: column;
            height: 60px;
            width: 100%;
        }
    
}

.blueDiv{
    display: flex;
    background-color: #c3e9fa;
    border-radius: 10px;
    width: 100%;
    height: 100px;

}