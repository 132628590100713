@import '../base/settings';
@media (min-width: 600px) {
    .navbar3 {
        visibility: hidden;
    }
    .navbar2 {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 101;
        width: 100%;
        height: 72px;
        background-color: #ffffff;
        // display: grid;
        // grid-template-columns: $marginLeft 20% 20% 30% 15% 5%;
        // grid-template-areas: 'null navb null2 null2 socialmedia null3';
        -webkit-box-shadow: -1px 5px 22px -3px rgba(0, 0, 0, 0.34);
        box-shadow: -1px 5px 22px -3px rgba(0, 0, 0, 0.34);
        .logo {
            grid-area: navb;
            display: flex;
            align-self: center;
        }
    }
}

$insideMarginLeft: 6%;
$insideMarginRight: 6%;
@media (max-width: 600px) {
    .navbarContainer {
        -webkit-box-shadow: -1px 5px 22px -3px rgba(0, 0, 0, 0.34);
        box-shadow: -1px 5px 22px -3px rgba(0, 0, 0, 0.34);
        display: grid;
        position: absolute;
        top: 0;
        left: 0;
        height: 50px;
        background-color: $background-secondary;
        z-index: 99;
        .logoNavBar {
            display: flex;
            align-self: center;
            margin: 10px 10px;
            justify-content: center;
            img {
                max-width: 140px;
            }
        }
        .iconMenu {
            display: flex;
            align-self: center;
            margin: 5px 10px;
            justify-content: center;
        }
        .helpNavBar {
            grid-area: help;
            display: flex;
            align-self: center;
            text-align: center;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            color: white;
            border: 1px solid white;
            border-radius: 5px;
            width: 85px;
            height: 22px;
        }
        .navbar4 {
            display: flex;
            align-self: center;
            margin: 5px 10px;
            justify-content: center;
        }
    }
    .navbar2 {
        visibility: hidden;
    }
}