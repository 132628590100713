@import '../base/settings';
.banner-offer-box {
    background-color: #092f57 !important;
    background-image: url(../../assets/background-web-simple-offer.svg);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: auto !important;
    min-height: 92vh !important;
    color: #ffffff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px !important;
    flex-direction: row-reverse;
    padding: 100px 30px 30px 80px;
}
.banner-offer-box h1 {
    font-size: 1.5em;
    padding-right: 0;
    text-align: left;
    margin-top: 0;
}
.banner-offer-box p.banner-highlight {
    font-size: 1.9rem;
    text-align: left;
    margin: 0.7rem 0;
    line-height: 30px;
}
.card-container {
    display: flex;
    justify-content: center;
}
.credit-card-img {
    max-width: 380px;
    padding-top: 0;
    margin-bottom: 20px;
}
.benefits-container {
    margin-top: 10px;
    padding: 0 25px;
}
.benefits-container h2 {
    font-size: 1.2em;
    font-weight: 400 !important;
    margin-bottom: 1rem;
}
.benefit-box {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border-bottom: 4px solid $primary;
    text-align: center;
    color: $primary;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    padding: 10px;
}
.disclaimer-text {
    font-size: 1em;
    line-height: 1.4em;
}
.disclaimer-text-migration {
    font-size: 11px !important;
    text-align: center;
    line-height: 20px;
}
.benefit-icon {
    width: 40px;
}
.link-text-button {
    padding: 0 !important;
    font-size: 1em !important;
    line-height: 0 !important;
    margin-bottom: 3px !important;
}
.link-white {
    color: white !important;
    text-decoration: underline !important;
}
.link-gray {
    color: white !important;
    text-decoration: underline !important;
}
.link-secondary-color {
    color: #203ae9 !important;
    text-decoration: none !important;
}
.condition-box {
    border: 1px solid $primary;
    border-radius: 20px;
    color: $primary;
    text-align: center;
    padding: 10px;
    font-size: 0.9em;
    margin: 10px 0;
}
.dialog-title-conditions h2 {
    font-size: 1.2rem;
    line-height: 1.3;
}
.dialog-text {
    font-size: 0.9rem;
}
.tc-text {
    padding: 0.5em 10px 1.2em 10px;
    font-size: 11px;
}
.disclaimer-title {
    font-size: 0.9rem;
    font-weight: bold;
    color: #636363;
}
.justify-terms {
    text-align: justify;
    font-size: 0.7rem;
}
.disclaimer-note {
    font-size: 0.8rem;
    color: gray;
}
.offerSelection {
    position: unset;
    bottom: unset;
    right: unset;
    z-index: 1;
    width: 100%;
    display: flex;
    align-items: flex-start !important;
    flex-direction: column;
    background-color: unset;
    min-height: unset;
    margin-bottom: 30px;
    margin-top: 20px;
}
@media (max-width: 1600px) {
    .banner-offer-box {
        padding: 90px 200px 30px;
        background-size: 25%;
        margin-bottom: 0px !important;
    }
}
@media (max-width: 1200px) {
    .banner-offer-box {
        margin-bottom: 0px !important;
    }
}
@media (max-width: 962px) {
    .banner-offer-box {
        background-color: #092f57 !important;
        background-image: url(../../assets/background-web-simple-offer.svg);
        background-repeat: no-repeat;
        background-position: top right;
        background-size: unset !important;
        min-height: 85vh !important;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0px !important;
        flex-direction: row;
        padding: 60px;
    }
    .link-gray {
        color: white !important;
    }
    .offerSelection {
        align-items: center !important;
    }
}
@media (orientation: landscape) and (max-width: 670px) {
    .credit-card-img {
        max-width: 30% !important;
        margin-top: 0vh !important;
        margin-bottom: 20px !important;
    }
    .disclaimer-text {
        position: unset !important;
        bottom: 30px !important;
        padding: 0 20px !important;
    }
    .banner-offer-box {
        min-height: 110vh !important;
    }
}

@media (max-width: 600px) {
    .banner-offer-box {
        background-image: url(../../assets/background-simple-offer.svg);
        background-repeat: no-repeat;
        background-position: top left;
        background-size: contain !important;
        padding: 20px 30px 30px;
        background-size: 40%;
        min-height: unset;
        justify-content: flex-start;
        margin-bottom: 10px !important;
        z-index: -1;
    }
    .credit-card-img {
        max-width: 70%;
        margin-top: 5vh;
        margin-bottom: 20px;
    }
    .banner-offer-box h1 {
        font-size: 1.3em;
        padding-right: 0;
        text-align: center;
        margin-top: 0;
    }
    .banner-offer-box p {
        font-size: 0.8rem;
        padding: 0;
    }
    .banner-offer-box p.banner-highlight {
        font-size: 1.75rem;
        text-align: center;
        margin: 0.7rem 0;
    }
    .dialog-title-conditions h2 {
        font-size: 1.1rem;
        line-height: 1.3;
    }
    .dialog-text {
        font-size: 0.9rem;
    }
    .condition-box {
        font-size: 0.8em;
    }
    .disclaimer-text {
        position: absolute;
        bottom: 140px;
        padding-right: 20px !important;
    }
    .disclaimer-title {
        font-size: 0.9rem;
        font-weight: bold;
        color: #636363;
    }
    .justify-terms {
        text-align: justify;
        font-size: 0.8rem;
    }
    .disclaimer-note {
        font-size: 0.9rem;
    }
    .offerSelection {
        position: fixed;
        bottom: 0px;
        left: 0px;
        z-index: 1;
        width: 100% !important;
        display: flex;
        flex-direction: row;
        justify-content: center !important;
        align-items: center !important;
        background-color: #ffffff;
        padding: 10px 0px 15px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        margin-bottom: unset;
        flex-direction: column;
    }
    .link-gray {
        color: gray !important;
    }
    .center-responsive{
        display: flex;
        justify-content: center;
        align-content: center;
    }
}
