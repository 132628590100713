
.validationContainer{
    margin-top:0;
}

@media (min-width: 1000px) {
.pushDiv{
        height: 18vh;
}
}
@media (max-width: 1000px) {
    .pushDiv{
        height: 0px;
    }
    }

@media (max-width: 768px) {
    .validationContainer{
        margin-top:30px;
    }
}