.button-container{
    padding: 10px 15px 10px 15px;
    width: 100%;
    box-shadow: unset;
}
.dni-container{
    margin-top: 50px !important;
    margin-left: 0 !important;
}

@media (max-width: 780px) {
    .button-container{
        padding: 10px 15px 10px 15px;
        width: 100%;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    }
    .dni-container{
        margin-top: 0 !important;
        margin-left: 0 !important;
    }
}