* {
    font-family: 'Titillium Web', sans-serif !important;
    margin: 0;
}

@media (max-width: 600px) {
    .mb-20-web {
        margin-bottom: unset !important;
    }
    .bg {
        background-color: $backgroundMobile;
    }
    .assetBg {
        position: absolute;
        top: 50px;
        background: rgb(255, 255, 255);
        background: -moz-linear-gradient(4deg, rgba(255, 255, 255, 0) 36%, rgba(111, 198, 231, 0.352000175070028) 85%),
            url('../../assets/background.svg');
        background:
            url('../../assets/background.svg'),
            - webkit-linear-gradient(4deg, rgba(255, 255, 255, 0) 36%, rgba(111, 198, 231, 0.352000175070028) 85%);
        background: linear-gradient(4deg, rgba(255, 255, 255, 0) 36%, rgba(111, 198, 231, 0.352000175070028) 85%), url('../../assets/background.svg');
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#6fc6e7",GradientType=1), url('../../assets/background.svg');
        background-repeat: no-repeat;
        background-position: top right;
        background-blend-mode: soft-light;
        height: 50%;
        width: 100%;
        pointer-events: none;
        z-index: 0;
    }
    .assetBgMessagePages {
        position: absolute;
        top: 50px;
        background: rgb(255, 255, 255);
        background: -moz-linear-gradient(4deg, rgba(255, 255, 255, 0) 36%, rgba(111, 198, 231, 0.352000175070028) 85%),
            url('../../assets/bg-message-pages.svg');
        background:
            url('../../assets/bg-message-pages.svg'),
            - webkit-linear-gradient(4deg, rgba(255, 255, 255, 0) 36%, rgba(111, 198, 231, 0.352000175070028) 85%);
        background: linear-gradient(4deg, rgba(255, 255, 255, 0) 36%, rgba(111, 198, 231, 0.352000175070028) 85%),
            url('../../assets/bg-message-pages.svg');
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#6fc6e7",GradientType=1), url('../../assets/bg-message-pages.svg');
        background-repeat: no-repeat;
        background-position: top right;
        background-blend-mode: soft-light;
        background-size: cover;
        height: 50%;
        width: 100%;
        pointer-events: none;
        z-index: -1;
    }
    .mb-40-res {
        margin-bottom: 40px !important;
    }
    .mb-100-res {
        margin-bottom: 100px !important;
    }
    .mb-130-res {
        margin-bottom: 130px !important;
    }
    .mb-160-res {
        margin-bottom: 160px !important;
    }
    .mt-20-web {
        margin-top: 0 !important;
    }
    .break-word {
        word-wrap: break-word;
        overflow-wrap: break-word;
        width: 100%;
    }
}

@media (min-width: 600px) {
    .bg {
        background-color: $backgroundDesktop;
    }
    .assetBg {
        position: absolute;
        top: 50px;
        background: rgb(255, 255, 255);
        background: -moz-linear-gradient(4deg, rgba(255, 255, 255, 0) 36%, rgba(111, 198, 231, 0.352000175070028) 85%),
            url('../../assets/background.svg');
        background:
            url('../../assets/background.svg'),
            - webkit-linear-gradient(4deg, rgba(255, 255, 255, 0) 36%, rgba(111, 198, 231, 0.352000175070028) 85%);
        background: linear-gradient(180.16deg, rgba(111, 198, 231, 0.32) 38.82%, rgba(196, 196, 196, 0) 92.94%), url('../../assets/background.svg');
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#6fc6e7",GradientType=1), url('../../assets/background.svg');
        background-repeat: no-repeat;
        background-position: top right;
        background-blend-mode: soft-light;
        height: 50%;
        width: 100%;
        z-index: 0;
        pointer-events: none;
    }
    .assetBgMessagePages {
        position: absolute;
        top: 50px;
        background: rgb(255, 255, 255);
        background: -moz-linear-gradient(4deg, rgba(255, 255, 255, 0) 36%, rgba(111, 198, 231, 0.352000175070028) 85%),
            url('../../assets/bg-message-pages-xl.svg');
        background:
            url('../../assets/bg-message-pages-xl.svg'),
            - webkit-linear-gradient(4deg, rgba(255, 255, 255, 0) 36%, rgba(111, 198, 231, 0.352000175070028) 85%);
        background: linear-gradient(180.16deg, rgba(111, 198, 231, 0.32) 38.82%, rgba(196, 196, 196, 0) 92.94%),
            url('../../assets/bg-message-pages-xl.svg');
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#6fc6e7",GradientType=1), url('../../assets/bg-message-pages-xl.svg');
        background-repeat: no-repeat;
        background-position: top right;
        background-size: cover;
        background-blend-mode: soft-light;
        height: 50%;
        width: 100%;
        z-index: -1;
        pointer-events: none;
    }
}

.fixed {
    position: fixed;
}

.flex {
    display: flex;
}

.flex-column-reverse {
    display: flex;
    flex-direction: column-reverse;
}

.relative {
    position: relative;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.center {
    display: flex;
    justify-content: center;
    align-content: center;
}

.verticalCenter {
    display: flex;
    align-content: center;
}

.verticalEnd {
    display: flex;
    align-content: flex-end;
}

.color-secondary {
    color: #203ae9;
}

.color-third {
    color: #9b9b9b;
}

.color-black {
    color: black;
}

.color-red {
    color: red;
}

.color-white {
    color: white;
}

.color-grey {
    color: #a5a5a5;
}

.inline-flex {
    display: inline-flex;
}

.link {
    text-transform: uppercase;
    text-decoration: none;
}
.no-text-transform {
    text-transform: none;
}
.uppercase {
    text-transform: uppercase;
}

.lh-100 {
    line-height: 100%;
}

.lh-160 {
    line-height: 160%;
}

.lh-200 {
    line-height: 200%;
}

.lh-300 {
    line-height: 300%;
}

.lh-400 {
    line-height: 400%;
}

.lh-500 {
    line-height: 500%;
}

.size-100 {
    width: 100%;
}

.size-75 {
    width: 75%;
}

.size-50 {
    width: 50%;
}

.size-25 {
    width: 25%;
}

.b1 {
    border: 2px solid red;
}

.b2 {
    border: 2px solid rgb(255, 238, 0);
}

.b3 {
    border: 2px solid rgb(0, 47, 255);
}

.b4 {
    border: 2px solid rgb(0, 255, 42);
}

.mb-63 {
    margin-bottom: 63px;
    background-color: black;
}

.mb-05 {
    margin-bottom: 05px;
}

.mb-1 {
    margin-bottom: 10px;
}

.mb-2 {
    margin-bottom: 20px;
}

.mb-3 {
    margin-bottom: 30px;
}

.mb-4 {
    margin-bottom: 40px;
}

.mb-5 {
    margin-bottom: 50px;
}

.mb-6 {
    margin-bottom: 60px;
}

.mb-7 {
    margin-bottom: 70px;
}

.mb-8 {
    margin-bottom: 80px;
}
.mb-100-res {
    margin-bottom: 20px;
}
.mb-130-res {
    margin-bottom: 10px;
}
.mb-160-res {
    margin-bottom: 20px;
}
.mb-20-web {
    margin-bottom: 20px !important;
}

.mt-05 {
    margin-top: 05px;
}

.mt-06 {
    margin-top: 06px;
}

.mt-07 {
    margin-top: 07px;
}

.mt-08 {
    margin-top: 08px;
}

.mt-09 {
    margin-top: 09px;
}

.mt-1 {
    margin-top: 10px;
}

.mt-1-5 {
    margin-top: 15px;
}

.mt-2 {
    margin-top: 20px;
}

.mt-3 {
    margin-top: 30px !important;
}

.mt-4 {
    margin-top: 40px !important;
}

.mt-150 {
    margin-top: 150px !important;
}

.mt-20-web {
    margin-top: 20px;
}

@media (orientation: landscape) {
    .mt-2-landscape {
        margin-top: 20px;
    }
    .mt-4-landscape {
        margin-top: 40px;
    }
}

.mt-5 {
    margin-top: 50px;
}

.mt-6 {
    margin-top: 60px;
}

.mt-8 {
    margin-top: 80px;
}

.mt-12 {
    margin-top: 120px;
}

.mt-10-vh {
    margin-top: 20vh;
}

.mt-50-vh {
    margin-top: 54vh;
}

.mt-10 {
    margin-top: 40px;
}

.ml-7 {
    margin-left: 70px;
}
.mlr-0 {
    margin-left: 0;
    margin-right: 0;
}
.m-0 {
    margin: 0 !important;
}
.ml-05 {
    margin-left: 5px;
}

.ml-1 {
    margin-left: 10px;
}

.ml-15 {
    margin-left: 15px;
}

.ml-2 {
    margin-left: 20px;
}

.ml-3 {
    margin-left: 30px;
}

.ml-10 {
    margin-left: 10px;
}

.ml-5 {
    margin-left: 9%;
}

.ml-20per {
    margin-left: 20%;
}

.ml-4 {
    margin-left: 40px;
}

.fw-200 {
    font-weight: 200;
}

.fw-300,
.fw-light {
    font-weight: 300;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700,
.fw-bold {
    font-weight: 700;
}

.fs-05 {
    font-size: 5px;
}

.fs-10 {
    font-size: 10px;
}

.fs-12 {
    font-size: 12px;
}

.fs-13 {
    font-size: 13px;
}

.fs-14 {
    font-size: 14px;
}

.fs-15 {
    font-size: 15px;
}
.fs-16 {
    font-size: 16px;
}
.fs-18 {
    font-size: 18px;
}

.fs-19 {
    font-size: 19px;
}

.fs-20 {
    font-size: 20px;
}

.fs-21 {
    font-size: 21px;
}

.fs-22 {
    font-size: 22px;
}

.fs-23 {
    font-size: 23px;
}

.fs-24 {
    font-size: 24px;
}

.fs-25 {
    font-size: 25px;
}

.fs-28 {
    font-size: 28px;
}

.fs-30 {
    font-size: 30px;
}
.fs-30-res {
    font-size: 30px;
}

.fs-35 {
    font-size: 35px;
}

.fs-38 {
    font-size: 38px;
}

.fs-40 {
    font-size: 40px;
}

.fs-45 {
    font-size: 45px;
}

.fst-reg {
    font-style: normal;
}

.fs-50 {
    font-size: 50px;
}

.ls-33 {
    letter-spacing: 0.33px;
}

.ml-05-per {
    margin-left: 5%;
}

.ml-03-per {
    margin-left: 3%;
}

.ml-10-per {
    margin-left: 10%;
}

.ml-20-per {
    margin-left: 20%;
}

.ml-30-per {
    margin-left: 30%;
}

.ml-40-per {
    margin-left: 40%;
}

.ml-50-per {
    margin-left: 50%;
}

.mb-10-per {
    margin-bottom: 10%;
}

.mb-20-per {
    margin-bottom: 20%;
}

.mb-30-per {
    margin-bottom: 30%;
}

.mb-40-per {
    margin-bottom: 40%;
}

.mb-50-per {
    margin-bottom: 50%;
}

.mb-10 {
    margin-bottom: 10px;
}

.width-10-per {
    width: 10%;
}

.width-20-per {
    width: 20%;
}

.width-30-per {
    width: 30%;
}

.width-40-per {
    width: 40%;
}

.width-50-per {
    width: 50%;
}

.width-60-per {
    width: 60%;
}

.width-70-per {
    width: 70%;
}

.width-80-per {
    width: 80%;
}

.width-90-per {
    width: 90%;
}

.width-100-per {
    width: 100% !important;
}

.height-10-per {
    height: 10%;
}

.height-20-per {
    height: 20%;
}

.height-30-per {
    height: 30%;
}

.height-40-per {
    height: 40%;
}

.height-45-per {
    height: 45%;
}

.height-50-per {
    height: 50%;
}

.height-100-per {
    height: 100%;
}

.padding-5 {
    padding: 5%;
}

/* Text alignment */

.text-align-right {
    text-align: right;
}
.text-align-center {
    text-align: center;
}

/* Colors */

.color-primary {
    color: $primary;
}

.color-gray {
    color: $gray;
}

.color-dark-gray {
    color: $darkGray;
}

.color-dark-blue {
    color: $darkBlue;
}

.color-darker-gray {
    color: $darkerGray;
}

.color-light-blue {
    color: $lightBlue;
}

.color-link-blue {
    color: $linkBlue;
}

.color-black {
    color: $black;
}

/* Opacity */

.opacity-30 {
    opacity: 0.3;
}

.opacity-50 {
    opacity: 0.5;
}

.opacity-70 {
    opacity: 0.7;
}

/* Fit to container */

.fit-container {
    object-fit: contain;
    max-width: 100%;
}

.z-index-high {
    z-index: 99;
}

.rem1 {
    font-size: 1rem;
}

.rem1-5 {
    font-size: 1.5rem;
}

.rem2 {
    font-size: 2rem;
}

.lh-20 {
    line-height: 20px;
}

@media (max-width: 600px) {
    .fs-30-res {
        font-size: 26px;
    }
}

@media (max-width: 600px) {
    .mb-footer-2 {
        margin-bottom: 83px;
    }
    .width-80-res {
        width: 80% !important;
    }
    .width-90-res {
        width: 90% !important;
    }
    .mt-0-res {
        margin-top: 0 !important;
    }
}