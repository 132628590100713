.btn-v1 {
    border-radius: 8px;
    outline: none;
    border: none;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    background-color: $primary;
    color: $button_text_v1;
    height: 30px;
    transition: 0.4s all;
    cursor: pointer;
    &:hover {
        box-shadow: 0 5px 18px rgba(65, 64, 64, 0.25), 0 10px 10px rgba(104, 104, 104, 0.22);
        background-color: $button_hover_v1;
    }
    &:active {
        transition: 0.1s;
        background-color: $button_active_v1;
    }
}

.startButton {
    position: unset;
    bottom: unset;
    right: unset;
    z-index: 1;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: unset;
    min-height: unset;
    margin-bottom: 30px;
}
.validationIdentityButton {
    position: unset;
    bottom: unset;
    right: unset;
    z-index: 101;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: unset;
    min-height: unset;
    margin-bottom: 30px !important;
    margin-top: 30px !important;
}
.rejectButton {
    position: unset;
    bottom: unset;
    right: unset;
    z-index: 1;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: unset;
    min-height: unset;
    margin-bottom: 30px;
}
.rejectButton button{
    margin-right: 10px;
    margin-bottom: 15px;
}
.fixed-bottom-button {
    position: unset;
    bottom: 0;
    left: 0;
}
.fixed-bottom-padding{
    padding-top: 20px; 
    padding-bottom: 40px
}

.recaptcha-custom {
    .grecaptcha-badge{
        z-index: 300;
    }
}

@media (max-width: 780px) {
    .fixed-bottom-button {
        position: fixed !important;
        bottom: 0;
        left: 0;
    }
}

@media (min-width: 600px) {
    .buttonNext {
        width: 600px;
    }
}

@media (max-width: 600px) {
    .startButton {
        position: fixed;
        bottom: 0px;
        left: 0px;
        z-index: 1;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF;
        min-height: 54px;
        padding: 10px 0px 15px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        margin-bottom: unset;
    }
    .validationIdentityButton {
        position: fixed;
        bottom: 0px;
        left: 0px;
        z-index: 101;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF;
        min-height: 54px;
        padding: 10px 0px 15px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        margin-bottom: unset !important;
        margin-top: unset !important;
    }

    .rejectButton {
        z-index: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF;
        min-height: 54px;
        padding: 10px 0px 15px;
        margin-bottom: unset;
        margin-top: 10px;
    }
    .rejectButton button{
        margin-right: 0;
        margin-bottom: 15px;
    }
}
