.background-loader{

    opacity: .2;
    position: absolute;
    z-index: 99;
    width: 100%;
    height: 100%;
    background-color: black;
    

  }