@import '../base/settings';

.imgcontentError {
    display: inline-flex;
    padding-top: 15%;
    .righSideError {
        display: flex;
        justify-content: left;
        align-items: flex-start;
        width: 100%;
    }
    .leftSideError {
        padding-top: 2%;
        width: 60%;
        .titleError {
        }
        .subTitleError {
            margin-top: 5%;
        }
        .subTitle2Error {
            margin-top: 5%;
        }
        .buttonError {
            margin-top: 11%;
            width: 100%;
        }
    }
}
.error-container{
    margin-top: 70px;
}
